import { axiosApi } from "../../helpers/api";

const BASE_API_URL = 'https://backend.idbookhotels.com/api/v1/org-resources/company-details/'  // Replace with your company API endpoint

class CompanyDetailService {
    // Retrieve a list of company details with pagination support
    getCompanyDetails(limit, offset) {
        return axiosApi.get(`/org-resources/company-details/?limit=${limit}&offset=${offset}`);
    }

    // Retrieve a company detail by ID
    getCompanyDetailById(id) {
        return axiosApi.get(`${BASE_API_URL}${id}/`);
    }

    // Create a new company detail
    createCompanyDetail(data) {
        return axiosApi.post(BASE_API_URL, data);
    }

    // Update a company detail by ID
    updateCompanyDetail(data, id) {
        return axiosApi.put(`${BASE_API_URL}${id}/`, data);
    }

    // Delete a company detail by ID
    deleteCompanyDetail(id) {
        return axiosApi.delete(`${BASE_API_URL}${id}/`);
    }
}

export default new CompanyDetailService();
