import { axiosApi } from "../../helpers/api";

const BASE_API_URL = 'https://backend.idbookhotels.com/api/v1/org-resources/occupancies/'  // Replace with your Occupancy API endpoint

class OccupancyService {
    // Retrieve a list of occupancies with pagination support
    getOccupancies(limit, offset) {
        return axiosApi.get(`/org-resources/occupancies/?limit=${limit}&offset=${offset}`);
    }

    // Retrieve an occupancy by ID
    getOccupancyById(id) {
        return axiosApi.get(`${BASE_API_URL}${id}/`);
    }

    // Create a new occupancy
    createOccupancy(data) {
        return axiosApi.post(BASE_API_URL, data);
    }

    // Update an occupancy by ID
    updateOccupancy(data, id) {
        return axiosApi.put(`${BASE_API_URL}${id}/`, data);
    }

    // Delete an occupancy by ID
    deleteOccupancy(id) {
        return axiosApi.delete(`${BASE_API_URL}${id}/`);
    }
}

export default new OccupancyService();
