import React, { useEffect, useState } from "react";
import FaqService from "../../services/org_resources/FAQsService"; // Import your FaqService

const FaqPage = () => {
    const [faqs, setFaqs] = useState([]);

    const fetchFrequentlyAskedQuestions = async () => {
        try {
            const response = await FaqService.getFrequentlyAskedQuestions();
            if (response.status === 'success') {
                setFaqs(response.data);
            }
        } catch (error) {
            // Handle errors
        }
    };

    useEffect(() => {
        fetchFrequentlyAskedQuestions();
    }, []);

    return (
        <div>
            <h1>Frequently Asked Questions (FAQs)</h1>
            <ul>
                {faqs.map((faq) => (
                    <li key={faq.id}>
                        <h2>{faq.title}</h2>
                        <p>{faq.description}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default FaqPage;
