import React from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import { Button } from 'react-bootstrap';

const DataTable = ({ data, columns, pageIndex, pageCount, setPageIndex, previous, next }) => {
    console.log("API_DATATAAAA", {
        "data": data,
        "pageIndex": pageIndex,
        "previous": previous,
        "next": next
    });


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        // pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageSize },
        // state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                // pageIndex: pageIndex,
                pageSize: 8,
            },
        },
        useSortBy,
        usePagination
    );

    return (
        <div>
            <div className='table_container'>
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <div className="d-flex justify-content-md-end justify-content-center align-items-center mt-3">
                <Button
                    className="btn_pagination rounded-start"
                    onClick={() => setPageIndex(pageIndex - 1)}
                    disabled={!previous}
                >
                    Previous
                </Button>

                <div className="d-flex justify-content-md-start justify-content-center align-items-center">
                    <Button
                        className="btn_pagination border-start-0"
                        onClick={() => setPageIndex(pageIndex - 1)}
                        disabled={!previous}
                    >
                        {pageIndex}
                    </Button>

                    <div className="pagination_curr_page border-top-0">
                        {pageIndex + 1}
                    </div>

                    <Button
                        className="btn_pagination border-end-0"
                        onClick={() => setPageIndex(pageIndex + 1)}
                        disabled={!next}
                    >
                        {pageIndex + 2}
                    </Button>
                </div>

                <Button
                    className="btn_pagination rounded-end"
                    onClick={() => setPageIndex(pageIndex + 1)}
                    disabled={!next}
                >
                    Next
                </Button>
            </div>
        </div>
    );
}

export default DataTable;