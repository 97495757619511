import { axiosApi } from "../../helpers/api";

const BASE_API_URL = 'https://backend.idbookhotels.com/api/v1/org-resources/career/'  // Replace with your Career API endpoint

class CareerService {
    // Retrieve a list of "Career" opportunities
    getCareerOpportunities() {
        return axiosApi.get(BASE_API_URL);
    }
}

export default new CareerService();
