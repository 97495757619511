import { axiosApi } from "../../helpers/api";

const BASE_API_URL = 'https://backend.idbookhotels.com/api/v1/org-resources/refund-cancellation-policy/'  // Replace with your Refund and Cancellation Policy API endpoint

class RefundAndCancellationPolicyService {
    // Retrieve the "Refund and Cancellation Policy" content
    getRefundAndCancellationPolicy() {
        return axiosApi.get(BASE_API_URL);
    }
}

export default new RefundAndCancellationPolicyService();
