import { axiosApi } from "../../helpers/api";

const BASE_API_URL = 'https://backend.idbookhotels.com/api/v1/booking/applied-coupons/' // Adjust the API endpoint accordingly

class AppliedCouponsService {
    getAppliedCoupons(limit, offset) {
        return axiosApi.get(`/booking/applied-coupons/?limit=${limit}&offset=${offset}`);
    }
    
    // getAppliedCoupons() {
    //     return axiosApi.get(BASE_API_URL);
    // }

    getAppliedCouponById(id) {
        return axiosApi.get(`${BASE_API_URL}${id}/`);
    }

    createAppliedCoupon(data) {
        return axiosApi.post(BASE_API_URL, data);
    }

    updateAppliedCoupon(data, id) {
        return axiosApi.put(`${BASE_API_URL}${id}/`, data);
    }

    deleteAppliedCoupon(id) {
        return axiosApi.delete(`${BASE_API_URL}${id}/`);
    }
}

export default new AppliedCouponsService();
