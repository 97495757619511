import { axiosApi } from "../../helpers/api";

const BASE_API_URL = 'https://backend.idbookhotels.com/api/v1/org-resources/faqs/'  // Replace with your FAQs API endpoint

class FaqService {
    // Retrieve the list of FAQs
    getFrequentlyAskedQuestions() {
        return axiosApi.get(BASE_API_URL);
    }
}

export default new FaqService();
