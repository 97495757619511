import axios from "axios";

const API_BASE_URL = "https://backend.idbookhotels.com/api/v1";

class UserRolesAndPermissionsService {
    getRoleAndPermissionByUserId(userId, token) {
        const url = `${API_BASE_URL}/users/${userId}/roles_and_permissions/`;

        return axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            // console.log("RE_RE_RE", response);
            return response.data;
        })
        .catch(error => {
            console.log(error);
            throw error;
        });
    }
}

export default new UserRolesAndPermissionsService();
