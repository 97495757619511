import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Table } from 'react-bootstrap';
import { useTable, useSortBy } from "react-table";
import CompanyDetailService from "../../services/org_resources/CompanyDetailService"; // Import your CompanyDetailService
import toastr from "toastr";

import { PiEyeBold } from 'react-icons/pi';
import { BiSolidPencil, BiSolidTrashAlt, BiSearch } from 'react-icons/bi';
import { MdOutlineAddCircleOutline } from 'react-icons/md'
import DataTable from "../../components/Pages/DataTable";

const tableColumns = [
    {
        Header: "ID",
        accessor: "id"
    },
    {
        Header: "Company Name",
        accessor: "company_name"
    },
    {
        Header: "Company Phone",
        accessor: "company_phone"
    },
    {
        Header: "Company Email",
        accessor: "company_email"
    },
    {
        Header: "Full Address",
        accessor: "full_address"
    },
    {
        Header: "District",
        accessor: "district"
    },
    {
        Header: "State",
        accessor: "state"
    },
    {
        Header: "Country",
        accessor: "country"
    },
    {
        Header: "Pin Code",
        accessor: "pin_code"
    },
    {
        Header: "Location",
        accessor: "location",
        Cell: ({ row }) => (
            <a href={row.values.location} target="_blank" rel="noopener noreferrer">View on Map</a>
        )
    },
    {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
            <div className="d-flex align-items-center gap-3">
                <Link to={`/company/detail/${row.values.id}`}><PiEyeBold color="#F89752" /></Link>
                <Link to={`/company/update/${row.values.id}`}><BiSolidPencil color="#1AB69D" /></Link>
            </div>
        )
    }
];

const CompanyDetailPage = () => {
    const navigate = useNavigate();
    const columns = useMemo(() => tableColumns, []);

    const [tableData, setTableData] = useState({
        data: [],
        pageCount: 0,
        pageIndex: 0,
        pageSize: 8,
        previous: null,
        next: null,
    });


    const getCompanyDetailData = async (limit, offset) => {
        await CompanyDetailService.getCompanyDetails(limit, offset).then((res) => {
            if (res.status === 'success') {
                setTableData({
                    data: res.data.results,
                    pageCount: Math.ceil(res.data.count / tableData.pageSize),
                    pageIndex: tableData.pageIndex,
                    pageSize: tableData.pageSize,
                    previous: res.data.previous,
                    next: res.data.next,
                });
            }
        });
    }

    useEffect(() => {
        getCompanyDetailData(tableData.pageSize, tableData.pageIndex * tableData.pageSize);
    }, [tableData.pageIndex]);

    const onPressSetPageIndex = (newPageIndex) => {
        setTableData({ ...tableData, pageIndex: newPageIndex })
    }

    return (
        <div className="table_wrapper">
            <div className="d-flex justify-content-end mb-3">
                <div className="search_box">
                    <BiSearch />
                    <input type="search" placeholder="Type here..." />
                </div>
                <Link className="add_btn"> <MdOutlineAddCircleOutline className="add_btn_icon" /> Add new Company </Link>
            </div>

            <div>
                {tableData &&
                    <DataTable
                        data={tableData.data}
                        columns={columns}
                        pageIndex={tableData.pageIndex}
                        pageCount={tableData.pageCount}
                        setPageIndex={(newPageIndex) => onPressSetPageIndex(newPageIndex)}
                        previous={tableData.previous}
                        next={tableData.next}
                    />
                }
            </div>
        </div>
    )
}

export default CompanyDetailPage;
