import { axiosApi } from "../../helpers/api";

const BASE_API_URL = 'https://backend.idbookhotels.com/api/v1/coupons/coupons/' // Adjust the API endpoint accordingly

class CouponsService {
    getCoupons(limit, offset) {
        return axiosApi.get(`/coupons/coupons/?limit=${limit}&offset=${offset}`);
    }
    // getCoupons() {
    //     return axiosApi.get(BASE_API_URL);
    // }

    getCouponById(id) {
        return axiosApi.get(`${BASE_API_URL}${id}/`);
    }

    createCoupon(data) {
        return axiosApi.post(BASE_API_URL, data);
    }

    updateCoupon(data, id) {
        return axiosApi.put(`${BASE_API_URL}${id}/`, data);
    }

    deleteCoupon(id) {
        return axiosApi.delete(`${BASE_API_URL}${id}/`);
    }
}

export default new CouponsService();
