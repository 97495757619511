import { axiosApi } from "../../helpers/api";

const BASE_API_URL = 'https://backend.idbookhotels.com/api/v1/org-resources/room-types/'  // Replace with your Room Type API endpoint

class RoomTypeService {
    // Retrieve a list of room types with pagination support
    getRoomTypes(limit, offset) {
        return axiosApi.get(`/org-resources/room-types/?limit=${limit}&offset=${offset}`);
    }

    // Retrieve a room type by ID
    getRoomTypeById(id) {
        return axiosApi.get(`${BASE_API_URL}${id}/`);
    }

    // Create a new room type
    createRoomType(data) {
        return axiosApi.post(BASE_API_URL, data);
    }

    // Update a room type by ID
    updateRoomType(data, id) {
        return axiosApi.put(`${BASE_API_URL}${id}/`, data);
    }

    // Delete a room type by ID
    deleteRoomType(id) {
        return axiosApi.delete(`${BASE_API_URL}${id}/`);
    }
}

export default new RoomTypeService();
