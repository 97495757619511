import React, { useEffect, useState } from "react";
import LegalityService from "../../services/org_resources/LegalityService"; // Import your LegalityService

const LegalityPage = () => {
    const [legality, setLegality] = useState({
        id: null,
        title: "",
        description: "",
        image: null,
        active: false,
        created: "",
        updated: "",
    });

    const fetchLegality = async () => {
        try {
            const response = await LegalityService.getLegality();
            if (response.status === 'success') {
                setLegality(response.data);
            }
        } catch (error) {
            // Handle errors
        }
    };

    useEffect(() => {
        fetchLegality();
    }, []);

    return (
        <div>
            <h1>{legality.title}</h1>
            <p>{legality.description}</p>
        </div>
    );
};

export default LegalityPage;
