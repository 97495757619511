import { axiosApi } from "../../helpers/api";

const BASE_API_URL = 'https://backend.idbookhotels.com/api/v1/org-resources/enquiries/'  // Replace with your Enquiry API endpoint

class EnquiryService {
    // Retrieve a list of enquiries with pagination support
    getEnquiries(limit, offset) {
        return axiosApi.get(`/org-resources/enquiries/?limit=${limit}&offset=${offset}`);
    }

    // Retrieve an enquiry by ID
    getEnquiryById(id) {
        return axiosApi.get(`${BASE_API_URL}${id}/`);
    }

    // Create a new enquiry
    createEnquiry(data) {
        return axiosApi.post(BASE_API_URL, data);
    }

    // Update an enquiry by ID
    updateEnquiry(data, id) {
        return axiosApi.put(`${BASE_API_URL}${id}/`, data);
    }

    // Delete an enquiry by ID
    deleteEnquiry(id) {
        return axiosApi.delete(`${BASE_API_URL}${id}/`);
    }
}

export default new EnquiryService();
