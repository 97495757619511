import React, { useEffect, useState } from "react";
import CareerService from "../../services/org_resources/CareerService"; // Import your CareerService

const CareerPage = () => {
    const [careerOpportunities, setCareerOpportunities] = useState([]);

    const fetchCareerOpportunities = async () => {
        try {
            const response = await CareerService.getCareerOpportunities();
            if (response.status === 'success') {
                setCareerOpportunities(response.data.results);
            }
        } catch (error) {
            // Handle errors
        }
    };

    useEffect(() => {
        fetchCareerOpportunities();
    }, []);

    return (
        <div>
            <h1>Career Opportunities</h1>
            <ul>
                {careerOpportunities.map((opportunity) => (
                    <li key={opportunity.id}>
                        <h2>{opportunity.title}</h2>
                        <p>{opportunity.description}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CareerPage;
