import { axiosApi } from "../../helpers/api";

const BASE_API_URL = 'https://backend.idbookhotels.com/api/v1/org-resources/amenities/'  // Replace with your Amenity API endpoint

class AmenityService {
    // Retrieve a list of amenities with pagination support
    getAmenities(limit, offset) {
        return axiosApi.get(`/org-resources/amenities/?limit=${limit}&offset=${offset}`);
    }

    // Retrieve an amenity by ID
    getAmenityById(id) {
        return axiosApi.get(`${BASE_API_URL}${id}/`);
    }

    // Create a new amenity
    createAmenity(data) {
        return axiosApi.post(BASE_API_URL, data);
    }

    // Update an amenity by ID
    updateAmenity(data, id) {
        return axiosApi.put(`${BASE_API_URL}${id}/`, data);
    }

    // Delete an amenity by ID
    deleteAmenity(id) {
        return axiosApi.delete(`${BASE_API_URL}${id}/`);
    }
}

export default new AmenityService();
