import React, { useEffect, useState } from "react";
import AboutUsService from "../../services/org_resources/AboutUsService"; // Import your AboutUsService
import toastr from "toastr";

const AboutUsPage = () => {
    const [aboutUs, setAboutUs] = useState({
        id: null,
        title: "",
        description: "",
        image: null,
        active: false,
        created: "",
        updated: "",
    });

    const [isEditing, setIsEditing] = useState(false);

    const fetchAboutUs = async () => {
        try {
            const response = await AboutUsService.getAboutUs();
            if (response.status === 'success') {
                setAboutUs(response.data);
            } else {
                toastr.error('Failed to fetch "About Us" content.');
            }
        } catch (error) {
            toastr.error('Failed to fetch "About Us" content.');
        }
    };

    useEffect(() => {
        fetchAboutUs();
    }, []);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = async () => {
        try {
            const response = await AboutUsService.updateAboutUs(aboutUs);
            if (response.status === 'success') {
                toastr.success('About Us content updated successfully.');
            } else {
                toastr.error('Failed to update "About Us" content.');
            }
        } catch (error) {
            toastr.error('Failed to update "About Us" content.');
        } finally {
            setIsEditing(false);
        }
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        // Reset the "About Us" content if the user cancels editing.
        fetchAboutUs();
    };

    return (
        <div>
            <h1>About Us</h1>
            {isEditing ? (
                <div>
                    <textarea
                        rows="5"
                        cols="50"
                        value={aboutUs.description}
                        onChange={(e) =>
                            setAboutUs({ ...aboutUs, description: e.target.value })
                        }
                    />
                    <br />
                    <button onClick={handleSaveClick}>Save</button>
                    <button onClick={handleCancelClick}>Cancel</button>
                </div>
            ) : (
                <div>
                    <p>{aboutUs.description}</p>
                    <button onClick={handleEditClick}>Edit</button>
                </div>
            )}
        </div>
    );
};

export default AboutUsPage;
