import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import PermissionService from "../../services/PermissionService";
import toastr from "toastr";
import RoleService from "../../services/RoleService";


const UpdateView = () => {
    const navigate = useNavigate();
    const { mobileNumber } = useParams();
    const [userDetail, setUserDetail] = useState(null);
    const [accessToken, setAccessToken] = useState('');
    const [permissions, setPermissions] = useState([]);
    const [roles, setRoles] = useState([]);
    const [updateUserDetails, setUpdateUserDetails] = useState(
        {
            "password": "",
            "mobile_number": "",
            "first_name": "",
            "last_name": "",
            "roles": [
                4
            ]
        }
    );



    const onInputChange = (e) => {
        if (e.target.name === 'roles') {
            setUpdateUserDetails({ ...updateUserDetails, [e.target.name]: [parseInt(e.target.value)] });
        } else {
            setUpdateUserDetails({ ...updateUserDetails, [e.target.name]: e.target.value });
        }
    }


    useEffect(() => {
        UserService.getUserByMobile(mobileNumber)
            .then((res) => {
                setUserDetail(res.data);

                if (res.data) {
                    setUpdateUserDetails({
                        "password": res?.data?.password ?? "",
                        "mobile_number": res?.data?.mobile_number ?? "",
                        "first_name": res?.data?.first_name ?? "",
                        "last_name": res?.data?.last_name ?? "",
                        "roles": res?.data?.roles ?? []
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [mobileNumber]);

    useEffect(() => {
        // PermissionService.getPermission().then((res) => {
        //     setPermissions(res.data.permissions);
        // });
        RoleService.getRoles().then((res) => {
            console.log("ROLES_RESSSS", res);
            setRoles(res.data.results);
        });
    }, [mobileNumber]);

    const updateUser = async (e) => {
        e.preventDefault();
        await UserService.updateUser(updateUserDetails, mobileNumber)
            .then((res) => {
                if (res.status === 'success') {
                    toastr.success('User Updated Successfully');
                    navigate('/users');
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }


    return (
        <div>
            {userDetail &&
                <form onSubmit={updateUser}>
                    <div className="row">
                        <div className="col-md-6">
                            <div class="mb-3">
                                <label for="first_name" class="form-label">First Name</label>
                                <input type="text"
                                    value={updateUserDetails.first_name}
                                    class="form-control"
                                    name="first_name"
                                    placeholder="First Name"
                                    onChange={(e) => onInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div class="mb-3">
                                <label for="last_name" class="form-label">Last Name</label>
                                <input type="text"
                                    value={updateUserDetails.last_name}
                                    class="form-control"
                                    name="last_name"
                                    placeholder="Last Name"
                                    onChange={(e) => onInputChange(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div class="mb-3">
                                <label for="mobile_number" class="form-label">Mobile Number</label>
                                <input type="text"
                                    readOnly={true}
                                    value={updateUserDetails.mobile_number}
                                    class="form-control"
                                    name="mobile_number"
                                    placeholder="Mobile Number"
                                    onChange={(e) => onInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div class="mb-3">
                                <label for="password" class="form-label">Password</label>
                                <input type="text"
                                    value={updateUserDetails.password}
                                    class="form-control"
                                    name="password"
                                    placeholder="Password"
                                    onChange={(e) => onInputChange(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            {roles.length > 1 && <div class="mb-3">
                                <label for="roles" class="form-label">Role</label>
                                <select defaultValue={updateUserDetails.roles[0]} className="form-control" name="roles"
                                    onChange={(e) => onInputChange(e)}
                                >
                                    {roles.map((role) => (
                                        <option key={role.id} value={role.id}>{role.name}</option>
                                    ))}
                                </select>
                            </div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="mt-3">
                                <button variant="primary" type="submit" className="global_btn" onClick={updateUser}>Update</button>
                            </div>
                        </div>
                    </div>
                </form>
            }
        </div>
    )
}


export default UpdateView;