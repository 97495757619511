import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Table } from 'react-bootstrap';
import { useTable, useSortBy } from "react-table";
import GalleryService from "../../services/hotels/GalleriesService"; // Import your GalleryService
import toastr from "toastr";

import { PiEyeBold } from 'react-icons/pi';
import { BiSolidPencil, BiSolidTrashAlt, BiSearch } from 'react-icons/bi';
import { MdOutlineAddCircleOutline } from 'react-icons/md'
import DataTable from "../../components/Pages/DataTable";

const tableColumn = [
    {
        Header: "ID",
        accessor: "id"
    },
    {
        Header: "Image Type",
        accessor: "image_type"
    },
    {
        Header: "Upload Image",
        accessor: "upload_image",
        Cell: ({ row }) => (
            <img src={row.values.upload_image} alt={`Image ${row.values.id}`} style={{ maxWidth: "100px" }} />
        )
    },
    {
        Header: "Active",
        accessor: "active"
    },
    {
        Header: "Created",
        accessor: "created"
    },
    {
        Header: "Updated",
        accessor: "updated"
    },
    {
        Header: "Property",
        accessor: "property"
    },
    {
        Header: "Room",
        accessor: "room"
    },
    {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
            <div className="d-flex align-items-center gap-3">
                <Link to={`/gallery/detail/${row.values.id}`}><PiEyeBold color="#F89752" /></Link>
                <Link to={`/gallery/update/${row.values.id}`}><BiSolidPencil color="#1AB69D" /></Link>
            </div>
        )
    }
];

const GalleryPage = () => {
    const navigate = useNavigate();
    const columns = useMemo(() => tableColumn, []);

    const [tableData, setTableData] = useState({
        data: [],
        pageCount: 0,
        pageIndex: 0,
        pageSize: 8,
        previous: null,
        next: null,
    });

    const getGalleryData = async (limit, offset) => {
        await GalleryService.getGalleryImages(limit, offset).then((res) => {
            if (res.status === 'success') {
                setTableData({
                    data: res.data.results,
                    pageCount: Math.ceil(res.data.count / tableData.pageSize),
                    pageIndex: tableData.pageIndex,
                    pageSize: tableData.pageSize,
                    previous: res.data.previous,
                    next: res.data.next,
                });
            }
        });
    }

    useEffect(() => {
        getGalleryData(tableData.pageSize, tableData.pageIndex * tableData.pageSize);
    }, [tableData.pageIndex]);

    const onPressSetPageIndex = (newPageIndex) => {
        setTableData({ ...tableData, pageIndex: newPageIndex })
    }

    return (
        <div className="table_wrapper">
            <div className="d-flex justify-content-end mb-3">
                <div className="search_box">
                    <BiSearch />
                    <input type="search" placeholder="Type here..." />
                </div>
                <Link className="add_btn"> <MdOutlineAddCircleOutline className="add_btn_icon" /> Add new Gallery </Link>
            </div>

            <div>
                {tableData &&
                    <DataTable
                        data={tableData.data}
                        columns={columns}
                        pageIndex={tableData.pageIndex}
                        pageCount={tableData.pageCount}
                        setPageIndex={(newPageIndex) => onPressSetPageIndex(newPageIndex)}
                        previous={tableData.previous}
                        next={tableData.next}
                    />
                }
            </div>
        </div>
    )
}

export default GalleryPage;
