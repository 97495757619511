import React, { useEffect, useState } from "react";
import PrivacyPolicyService from "../../services/org_resources/PrivacyPolicyService"; // Import your PrivacyPolicyService

const PrivacyPolicyPage = () => {
    const [privacyPolicy, setPrivacyPolicy] = useState({
        id: null,
        title: "",
        description: "",
        image: null,
        active: false,
        created: "",
        updated: "",
    });

    const fetchPrivacyPolicy = async () => {
        try {
            const response = await PrivacyPolicyService.getPrivacyPolicy();
            if (response.status === 'success') {
                setPrivacyPolicy(response.data);
            }
        } catch (error) {
            // Handle errors
        }
    };

    useEffect(() => {
        fetchPrivacyPolicy();
    }, []);

    return (
        <div>
            <h1>{privacyPolicy.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: privacyPolicy.description }} />
        </div>
    );
};

export default PrivacyPolicyPage;
