import { axiosApi } from "../../helpers/api";

const BASE_API_URL = 'https://backend.idbookhotels.com/api/v1/org-resources/terms-and-conditions/'  // Replace with your Terms and Conditions API endpoint

class TermsAndConditionsService {
    // Retrieve the "Terms and Conditions" content
    getTermsAndConditions() {
        return axiosApi.get(BASE_API_URL);
    }
}

export default new TermsAndConditionsService();
