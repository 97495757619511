import { axiosApi } from "../../helpers/api";

const BASE_API_URL = 'https://backend.idbookhotels.com/api/v1/hotels/reviews/' // Adjust the API endpoint accordingly

class ReviewService {
    getReviews(limit, offset) {
        return axiosApi.get(`/hotels/reviews/?limit=${limit}&offset=${offset}`);
    }
    // getReviews() {
    //     console.log()
    //     return axiosApi.get(`${BASE_API_URL}`);
    // }

    getReviewById(id) {
        return axiosApi.get(`${BASE_API_URL}${id}/`);
    }

    createReview(data) {
        return axiosApi.post(BASE_API_URL, data);
    }

    updateReview(data, id) {
        return axiosApi.put(`${BASE_API_URL}${id}/`, data);
    }

    deleteReview(id) {
        return axiosApi.delete(`${BASE_API_URL}${id}/`);
    }
}

export default new ReviewService();
