import { axiosApi } from "../../helpers/api";

const BASE_API_URL = 'https://backend.idbookhotels.com/api/v1/org-resources/amenity-categories/'  // Replace with your Amenity Category API endpoint

class AmenityCategoryService {
    // Retrieve a list of amenity categories with pagination support
    getAmenityCategories(limit, offset) {
        return axiosApi.get(`/org-resources/amenity-categories/?limit=${limit}&offset=${offset}`);
    }

    // Retrieve an amenity category by ID
    getAmenityCategoryById(id) {
        return axiosApi.get(`${BASE_API_URL}${id}/`);
    }

    // Create a new amenity category
    createAmenityCategory(data) {
        return axiosApi.post(BASE_API_URL, data);
    }

    // Update an amenity category by ID
    updateAmenityCategory(data, id) {
        return axiosApi.put(`${BASE_API_URL}${id}/`, data);
    }

    // Delete an amenity category by ID
    deleteAmenityCategory(id) {
        return axiosApi.delete(`${BASE_API_URL}${id}/`);
    }
}

export default new AmenityCategoryService();
