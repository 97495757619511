import { axiosApi } from "../../helpers/api";

const BASE_API_URL = 'https://backend.idbookhotels.com/api/v1/hotels/galleries/'  // Replace with your gallery API endpoint

class GalleryService {
    getGalleryImages(limit, offset) {
        return axiosApi.get(`/hotels/galleries/?limit=${limit}&offset=${offset}`);
    }
    // getGalleryImages() {
    //     return axiosApi.get(BASE_API_URL);
    // }

    getGalleryImageById(id) {
        return axiosApi.get(`${BASE_API_URL}${id}/`);
    }

    createGalleryImage(data) {
        return axiosApi.post(BASE_API_URL, data);
    }

    updateGalleryImage(data, id) {
        return axiosApi.put(`${BASE_API_URL}${id}/`, data);
    }

}

export default new GalleryService();
