import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BookingsService from "../../services/booking/BookingsService"; // Import your BookingsService
import toastr from "toastr";
import { Button, Table } from 'react-bootstrap';
import { useTable, useSortBy } from "react-table";

import { PiEyeBold } from 'react-icons/pi';
import { BiSolidPencil, BiSolidTrashAlt, BiSearch } from 'react-icons/bi';
import { MdOutlineAddCircleOutline } from 'react-icons/md'
import DataTable from "../../components/Pages/DataTable";

const tableColumn = [
    {
        Header: "ID",
        accessor: "id"
    },
    {
        Header: "Booking Type",
        accessor: "booking_type"
    },
    {
        Header: "Room Type",
        accessor: "room_type"
    },
    {
        Header: "Check-in Time",
        accessor: "checkin_time"
    },
    {
        Header: "Check-out Time",
        accessor: "checkout_time"
    },
    {
        Header: "Bed Count",
        accessor: "bed_count"
    },
    {
        Header: "Person Capacity",
        accessor: "person_capacity"
    },
    {
        Header: "Child Capacity",
        accessor: "child_capacity"
    },
    {
        Header: "Deal Price",
        accessor: "deal_price"
    },
    {
        Header: "Discount",
        accessor: "discount"
    },
    {
        Header: "Final Amount",
        accessor: "final_amount"
    },
    {
        Header: "Status",
        accessor: "status"
    },
    {
        Header: "Active",
        accessor: "active"
    },
    {
        Header: "Created",
        accessor: "created"
    },
    {
        Header: "Updated",
        accessor: "updated"
    },
    {
        Header: "Property",
        accessor: "property"
    },
    {
        Header: "Room",
        accessor: "room"
    },
    {
        Header: "User",
        accessor: "user"
    },
    {
        Header: "Coupon",
        accessor: "coupon"
    },
    {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
            <div className="d-flex align-items-center gap-3">
                <Link to={`/bookings/detail/${row.values.id}`}><PiEyeBold color="#F89752" /></Link>
                <Link to={`/bookings/update/${row.values.id}`}><BiSolidPencil color="#1AB69D" /></Link>
            </div>
        )
    }
];

const BookingsPage = () => {
    const navigate = useNavigate();
    const [tableData, setTableData] = useState({
        data: [],
        pageCount: 0,
        pageIndex: 0,
        pageSize: 8,
        previous: null,
        next: null,
    });

    const columns = useMemo(() => tableColumn, []);

    const getBookingData = async (limit, offset) => {
        await BookingsService.getBookings(limit, offset).then((res) => {
            if (res.status === 'success') {
                setTableData({
                    data: res.data.results,
                    pageCount: Math.ceil(res.data.count / tableData.pageSize),
                    pageIndex: tableData.pageIndex,
                    pageSize: tableData.pageSize,
                    previous: res.data.previous,
                    next: res.data.next,
                });
            }
        });
    }

    useEffect(() => {
        getBookingData(tableData.pageSize, tableData.pageIndex * tableData.pageSize);
    }, [tableData.pageIndex]);

    const onPressSetPageIndex = (newPageIndex) => {
        setTableData({ ...tableData, pageIndex: newPageIndex })
    }

    return (
        <div className="table_wrapper">
            <div className="d-flex justify-content-end mb-3">
                <div className="search_box">
                    <BiSearch />
                    <input type="search" placeholder="Type here..." />
                </div>
                <Link className="add_btn"> <MdOutlineAddCircleOutline className="add_btn_icon" /> Add new Booking </Link>
            </div>

            <div>
                {tableData &&
                    <DataTable
                        data={tableData.data}
                        columns={columns}
                        pageIndex={tableData.pageIndex}
                        pageCount={tableData.pageCount}
                        setPageIndex={(newPageIndex) => onPressSetPageIndex(newPageIndex)}
                        previous={tableData.previous}
                        next={tableData.next}
                    />
                }
            </div>
        </div>
    )
}

export default BookingsPage;
