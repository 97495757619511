import { axiosApi } from "../helpers/api";

class RoleService {
    getRoles() {
        return axiosApi.get('/administrator/roles/');
    }
    
    getRoleById(id) {
        return axiosApi.get(`/administrator/roles/${id}/`);
    }

    updateRole(data, id) { // Fix the method name here
        return axiosApi.put(`/administrator/roles/${id}/`, data);
    }
}

export default new RoleService();
