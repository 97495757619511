import { axiosApi } from "../../helpers/api";

const BASE_API_URL = 'https://backend.idbookhotels.com/api/v1/org-resources/privacy-policy/'  // Replace with your Privacy Policy API endpoint

class PrivacyPolicyService {
    // Retrieve the "Privacy Policy" content
    getPrivacyPolicy() {
        return axiosApi.get(BASE_API_URL);
    }
}

export default new PrivacyPolicyService();
