import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PermissionService from "../../services/PermissionService";

import {
    PiEyeBold
} from 'react-icons/pi';
import {
    BiSolidPencil, BiSolidTrashAlt, BiSearch
} from 'react-icons/bi';
import { MdOutlineAddCircleOutline } from 'react-icons/md'
import DataTable from "../../components/Pages/DataTable";
import DataTableSecond from "../../components/Pages/DataTableSecond";




const tableColumn = [
    {
        Header: "ID",
        accessor: "id"
    },
    {
        Header: "Name",
        accessor: "name"
    },
    {
        Header: "Code Name",
        accessor: "codename"
    },
    // {
    //     Header: "Permissions",
    //     accessor: "permissions",
    //     Cell: ({ row }) => (
    //         <div>{row.values.permissions.length}</div>
    //     )
    // },
    {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
            <div className="d-flex align-items-center gap-3">
                {/* Add your action links/icons here */}
                <Link to={`/roles/update/${row.values.id}`}><BiSolidPencil color="#1AB69D" /></Link>
            </div>
        )
    }
]



const PermissionPage = () => {
    const [permissions, setPermissions] = useState([]);

    // const [tableData, setTableData] = useState({
    //     data: [],
    //     pageCount: 0,
    //     pageIndex: 0,
    //     pageSize: 8,
    //     previous: null,
    //     next: null,
    // });

    const columns = useMemo(() => tableColumn, []);
    const data = useMemo(() => permissions, [permissions]);

    // const getPermissionsData = async (limit, offset) => {
    //     await PermissionService.getPermission(limit, offset).then((res) => {
    //         if (res.status === 'success') {
    //             // setUsers(res.data.results)
    //             setTableData({
    //                 data: res.data.permissions,
    //                 pageCount: Math.ceil(res.data.count / tableData.pageSize),
    //                 pageIndex: tableData.pageIndex,
    //                 pageSize: tableData.pageSize,
    //                 previous: res.data.previous,
    //                 next: res.data.next,
    //             });
    //         }
    //     });
    // }

    // useEffect(() => {
    //     getPermissionsData(tableData.pageSize, tableData.pageIndex * tableData.pageSize);
    // }, [tableData.pageIndex]);

    // const onPressSetPageIndex = (newPageIndex) => {
    //     console.log("Page_index", newPageIndex)
    //     setTableData({ ...tableData, pageIndex: newPageIndex })
    // }

    useEffect(() => {
        let accessToken = window.localStorage.getItem("authorization");
        PermissionService.getPermission().then((res) => {
            if(res.status === 'success'){
                setPermissions(res.data.permissions);
            }
            // console.log("RESULTS_RES", res);
        });
    }, []);



    return (
        <div className="table_wrapper">
            <div className="d-flex justify-content-end mb-3">
                <div className="search_box">
                    <BiSearch />
                    <input type="search" placeholder="Type here..." />
                </div>
                <Link className="add_btn"> <MdOutlineAddCircleOutline className="add_btn_icon" /> Add new Permission </Link>
            </div>

            <div>
                {/* {tableData &&
                    <DataTable
                        data={tableData.data}
                        columns={columns}
                        pageIndex={tableData.pageIndex}
                        pageCount={tableData.pageCount}
                        setPageIndex={(newPageIndex) => onPressSetPageIndex(newPageIndex)}
                        previous={tableData.previous}
                        next={tableData.next}
                    />
                } */}

                {permissions &&
                    <DataTableSecond columns={columns} data={data} />
                }

            </div>


            {/* <table>
                <tbody>
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Code Name</th>
                    </tr>

                    {permissions.map((item, index) => (
                        <tr>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td>{item.codename}</td>
                        </tr>
                    ))}

                </tbody>
            </table> */}
        </div>
    )
}


export default PermissionPage;