import axios from "axios";
import { setTokens, getTokens, removeTokens } from "../helpers/tokens";

const LOGIN_REST_API_URL = 'https://backend.idbookhotels.com/api/v1/auth/login'

class LoginService {
    async login(loginInfo) {
        try {
            const response = await axios.post(LOGIN_REST_API_URL, loginInfo);
            const header = response.data.data[1].access;
            await setTokens(header);
            return response.data;
        } catch (error) {
            await removeTokens();
            throw error;
        }
    }

    async logout() {
        await removeTokens();
    }

    async isLoggedIn() {
        let token = await getTokens();
        return token !== null
    }
}
// eslint-disable-next-line
export default new LoginService();