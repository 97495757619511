import React, { useState } from 'react'
import { ReactDOM } from 'react';
import {
    FaTh, FaUserAlt, FaRegChartBar, FaCommentAlt,
    FaShoppingBag, FaThList, FaBars, FaChevronDown, FaChevronRight, FaKeycdn,
    FaUserCog, FaUsers, FaHotel
} from 'react-icons/fa'
import {TbBeach} from 'react-icons/tb'
import {BiSolidCoupon} from 'react-icons/bi'
import { GoDotFill } from 'react-icons/go';
import { NavLink, useLocation } from 'react-router-dom';
import Logo from '../../assets/images/logo.svg';
import LogoPng from '../../assets/images/idbook_logo.png';
import SideMenuItem from './SideMenuItem';


const SideBar = ({ children }) => {

    const [isOpen, setIsOpen] = useState(true);
    const [isShowSubMenu, setIsShowSubMenu] = useState(false);
    const location = useLocation();

    const toggle = () => setIsOpen(!isOpen);

    const toggleSubMenu = () => {
        setIsShowSubMenu(!isShowSubMenu);
    }

    const menuItem = [
        {
            path: '/dashboard',
            name: 'Dashboard',
            icon: <FaTh />,
            subMenues: []
        },
        {
            path: '/users',
            name: 'Roles & Permissions',
            icon: <FaUserAlt />,
            subMenues: [
                {
                    path: '/users',
                    name: 'Users',
                    icon: <GoDotFill />
                },
                {
                    path: '/roles',
                    name: 'Roles',
                    icon: <GoDotFill />
                },
                {
                    path: '/permissions',
                    name: 'Permissions',
                    icon: <GoDotFill />
                }
            ]
        },
        {
            path: '/booking',
            name: 'Bookings',
            icon: <FaUsers />,
            subMenues: [
                {
                    path: '/bookings',
                    name: 'Bookings',
                    icon: <GoDotFill />
                },
                {
                    path: '/applied-coupons',
                    name: 'Applied Coupons',
                    icon: <GoDotFill />
                }
            ]
        },
        {
            path: '/coupons',
            name: 'Coupons',
            icon: <BiSolidCoupon />,
            subMenues: [
                {
                    path: '/coupons',
                    name: 'Coupons',
                    icon: <GoDotFill />
                }
            ]
        },
        {
            path: '/customers',
            name: 'Customers',
            icon: <FaUsers />,
            subMenues: [
                {
                    path: '/customers',
                    name: 'Customers',
                    icon: <GoDotFill />
                }
            ]
        },
        {
            path: '',
            name: 'Holiday Packages',
            icon: <TbBeach />,
            subMenues: [
                {
                    path: '/role/create',
                    name: 'Create',
                    icon: <GoDotFill />
                },
                {
                    path: '/role/update',
                    name: 'Update',
                    icon: <GoDotFill />
                }
            ]
        },
        {
            path: '',
            name: 'Hotels',
            icon: <FaHotel />,
            subMenues: [
                {
                    path: '/properties',
                    name: 'Properties',
                    icon: <GoDotFill />
                },
                {
                    path: '/rooms',
                    name: 'Rooms',
                    icon: <GoDotFill />
                },
                {
                    path: '/galleries',
                    name: 'Gallery',
                    icon: <GoDotFill />
                },
                {
                    path: '/reviews',
                    name: 'Reviews',
                    icon: <GoDotFill />
                }
            ]
        },
        {
            path: '/org-resources',
            name: 'ORG Resources',
            icon: <FaUserCog />,
            subMenues: [
                {
                    path: '/company-details',
                    name: 'Company Detail',
                    icon: <GoDotFill />
                },
                {
                    path: '/amenity-categories',
                    name: 'Amenity Category',
                    icon: <GoDotFill />
                },
                {
                    path: '/amenities',
                    name: 'Amenity',
                    icon: <GoDotFill />
                },
                {
                    path: '/enquiries',
                    name: 'Enquiry',
                    icon: <GoDotFill />
                },
                {
                    path: '/room-types',
                    name: 'Room Type',
                    icon: <GoDotFill />
                },
                {
                    path: '/occupancies',
                    name: 'Occupancy',
                    icon: <GoDotFill />
                },
                {
                    path: '/about-us',
                    name: 'About Us',
                    icon: <GoDotFill />
                },
                {
                    path: '/privacy-policy',
                    name: 'Privacy Policy',
                    icon: <GoDotFill />
                },
                {
                    path: '/refund-and-cancellation-policy',
                    name: 'Refund And Cancellation Policy',
                    icon: <GoDotFill />
                },
                {
                    path: '/terms-and-conditions',
                    name: 'Terms And Conditions',
                    icon: <GoDotFill />
                },
                {
                    path: '/legality',
                    name: 'Legality',
                    icon: <GoDotFill />
                },
                {
                    path: '/career',
                    name: 'Career',
                    icon: <GoDotFill />
                },
                {
                    path: '/faqs',
                    name: 'FAQs',
                    icon: <GoDotFill />
                }
            ]
        }
    ]


    const showSidebar = true
    // const showSidebar = location.pathname !== '/';
    // const showSidebar = location.pathname !== '/about';
    // console.log(showSidebar)

    return (
        <div>

            <div className={`sidebar ${showSidebar ? 'show' : 'hide'} ${isOpen ? 'expanded' : 'collapsed'}`} style={{ width: isOpen ? '300px' : '85px' }}>
                <div className="top_section">
                    <div className='logo' style={{ display: isOpen ? 'block' : 'none' }}>
                        <img src={LogoPng} width={'94'} />
                    </div>
                    <div className='bars'>
                        <FaBars onClick={toggle} />
                    </div>
                </div>
                {menuItem.map((item, index) => (
                    <SideMenuItem key={index} icon={item.icon} name={item.name} path={item.path} isOpen={isOpen} subMenus={item.subMenues} />
                ))}
            </div>
        </div>
    )
}


export default SideBar;