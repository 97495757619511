import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UserService from "../../services/UserService";

const DetailView = () => {
    const { mobileNumber } = useParams();
    const [userDetail, setUserDetail] = useState(null);

    useEffect(() => {
        let accessToken = window.localStorage.getItem("authorization");
        UserService.getUserDetailByCustomId(mobileNumber, accessToken) // Use the correct method name
            .then((res) => {
                // console.log("User Detail Response:", res.data[0].roles_and_permissions.id);
                setUserDetail(res);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [mobileNumber]);

    return (
        <div>
            {userDetail ? (
                <div>
                    <div className="w-50">
                        <div className="d-flex justify-content-between">
                            <div className="mb-3">
                                <h4 className="hd">User Custom Id</h4>
                                <p>{userDetail.data[0].user.custom_id}</p>
                            </div>
                            <div className="mb-3">
                                <h4 className="hd">User Role</h4>
                                <p>{userDetail.data[0].roles_and_permissions.name}</p>
                            </div>
                        </div>


                        <div className="pb-3 d-flex justify-content-between">
                            <div className="mb-3">
                                <h4 className="hd">Mobile Number</h4>
                                <p>{userDetail.data[0].user.mobile_number}</p>
                            </div>
                            <div className="mb-3">
                                <h4 className="hd">Status:</h4>
                                <p>{userDetail.data[0].user.is_active ? "Active" : "Inactive"}</p>
                            </div>
                        </div>
                    </div>


                    {/* <h5>User Role Id: {userDetail.data[0].roles_and_permissions.id}</h5> */}
                    {/* <p>User Role Prefix: {userDetail.data[0].roles_and_permissions.short_code}</p> */}
                    <h6 className="hd">Permissions</h6>
                    <ul className="permissions_wrapper">
                        {userDetail.data[0].roles_and_permissions.permissions.map(permission => (
                            <li key={permission}>{permission}</li>
                        ))}
                    </ul>

                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default DetailView;
