import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import RoleService from "../../services/RoleService"; // Import your RoleService
import toastr from "toastr";
import { Button, Table, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTable, useSortBy } from "react-table";

import {
    PiEyeBold
} from 'react-icons/pi';
import {
    BiSolidPencil, BiSolidTrashAlt, BiSearch
} from 'react-icons/bi';
import { MdOutlineAddCircleOutline } from 'react-icons/md'

import DataTable from "../../components/Pages/DataTable";


// Add your role-related icons here

const tableColumn = [
    {
        Header: "ID",
        accessor: "id"
    },
    {
        Header: "Name",
        accessor: "name"
    },
    {
        Header: "Short Code",
        accessor: "short_code"
    },
    {
        Header: "Permissions",
        accessor: "permissions",
        Cell: ({ row }) => (
            <div>{row.values.permissions.length}</div>
        )
    },
    {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
            <div className="d-flex align-items-center gap-3">
                {/* Add your action links/icons here */}
                <Link to={`/roles/update/${row.values.id}`}><BiSolidPencil color="#1AB69D" /></Link>
            </div>
        )
    }
]

const RolesPage = () => {
    const navigate = useNavigate();

    const [tableData, setTableData] = useState({
        data: [],
        pageCount: 0,
        pageIndex: 0,
        pageSize: 8,
        previous: null,
        next: null,
    });

    const columns = useMemo(() => tableColumn, []);

    const getRoleData = async (limit, offset) => {
        await RoleService.getRoles(limit, offset).then((res) => {
            if (res.status === 'success') {
                setTableData({
                    data: res.data.results,
                    pageCount: Math.ceil(res.data.count / tableData.pageSize),
                    pageIndex: tableData.pageIndex,
                    pageSize: tableData.pageSize,
                    previous: res.data.previous,
                    next: res.data.next,
                });
            }
        });
    }

    useEffect(() => {
        getRoleData(tableData.pageSize, tableData.pageIndex * tableData.pageSize);
    }, [tableData.pageIndex]);

    const onPressSetPageIndex = (newPageIndex) => {
        console.log("Page_index", newPageIndex)
        setTableData({ ...tableData, pageIndex: newPageIndex })
    }

    return (
        <div className="table_wrapper">
            <div className="d-flex justify-content-end mb-3">
                <div className="search_box">
                    <BiSearch />
                    <input type="search" placeholder="Type here..." />
                </div>
                <Link className="add_btn"> <MdOutlineAddCircleOutline className="add_btn_icon" /> Add New Role </Link>
            </div>

            {tableData &&
                <DataTable
                    data={tableData.data}
                    columns={columns}
                    pageIndex={tableData.pageIndex}
                    pageCount={tableData.pageCount}
                    setPageIndex={(newPageIndex) => onPressSetPageIndex(newPageIndex)}
                    previous={tableData.previous}
                    next={tableData.next}
                />
            }
        </div>
    )
}

export default RolesPage;
