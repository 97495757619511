import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Table, Badge } from 'react-bootstrap';
import { useTable, useSortBy } from "react-table";
import AmenityService from "../../services/org_resources/AmenityService"; // Import your AmenityService
import toastr from "toastr";

import { PiEyeBold } from 'react-icons/pi';
import { BiSolidPencil, BiSolidTrashAlt, BiSearch } from 'react-icons/bi';
import { MdOutlineAddCircleOutline } from 'react-icons/md'
import DataTable from "../../components/Pages/DataTable";

const tableColumns = [
    {
        Header: "ID",
        accessor: "id"
    },
    {
        Header: "Title",
        accessor: "title"
    },
    {
        Header: "Status",
        accessor: "active",
        Cell: ({ row }) => (
            <div>
                {row.values.is_active ?
                    <Badge className="badge_active">Active</Badge>
                    :
                    <Badge className="badge_deactive">Inactive</Badge>
                }
            </div>

        )
    },
    {
        Header: "Created",
        accessor: "created"
    },
    {
        Header: "Updated",
        accessor: "updated"
    },
    {
        Header: "Amenity Category",
        accessor: "amenity_category"
    },
    {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
            <div className="d-flex align-items-center gap-3">
                <Link to={`/amenity/detail/${row.values.id}`}><PiEyeBold color="#F89752" /></Link>
                <Link to={`/amenity/update/${row.values.id}`}><BiSolidPencil color="#1AB69D" /></Link>
            </div>
        )
    }
];

const AmenityPage = () => {
    const navigate = useNavigate();
    const columns = useMemo(() => tableColumns, []);

    const [tableData, setTableData] = useState({
        data: [],
        pageCount: 0,
        pageIndex: 0,
        pageSize: 8,
        previous: null,
        next: null,
    });

    const getAmenityData = async (limit, offset) => {
        await AmenityService.getAmenities(limit, offset).then((res) => {
            if (res.status === 'success') {
                setTableData({
                    data: res.data.results,
                    pageCount: Math.ceil(res.data.count / tableData.pageSize),
                    pageIndex: tableData.pageIndex,
                    pageSize: tableData.pageSize,
                    previous: res.data.previous,
                    next: res.data.next,
                });
            }
        });
    }

    useEffect(() => {
        getAmenityData(tableData.pageSize, tableData.pageIndex * tableData.pageSize);
    }, [tableData.pageIndex]);

    const onPressSetPageIndex = (newPageIndex) => {
        setTableData({ ...tableData, pageIndex: newPageIndex })
    }

    return (
        <div className="table_wrapper">
            <div className="d-flex justify-content-end mb-3">
                <div className="search_box">
                    <BiSearch />
                    <input type="search" placeholder="Type here..." />
                </div>
                <Link className="add_btn"> <MdOutlineAddCircleOutline className="add_btn_icon" /> Add new Amenity </Link>
            </div>

            <div>
                {tableData &&
                    <DataTable
                        data={tableData.data}
                        columns={columns}
                        pageIndex={tableData.pageIndex}
                        pageCount={tableData.pageCount}
                        setPageIndex={(newPageIndex) => onPressSetPageIndex(newPageIndex)}
                        previous={tableData.previous}
                        next={tableData.next}
                    />
                }
            </div>
        </div>
    )
}

export default AmenityPage;
