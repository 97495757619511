import React, { useState } from "react";
import UserRoleAndPermissionsService from "../../services/UserRoleAndPermissionsService";

const UserRoleAndPermissionsPage = () => {
    const [userId, setUserId] = useState("");
    const [response, setResponse] = useState(null);

    const handleUserIdChange = (event) => {
        setUserId(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const accessToken = window.localStorage.getItem("authorization");
        UserRoleAndPermissionsService.getRoleAndPermissionByUserId(userId, accessToken)
            .then((res) => {
                // console.log("Response:", res);
                setResponse(res.data); // Use res.data here
            })
            .catch((error) => {
                console.log(error);
                // setResponse(null); // Clear the response in case of an error
            });
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <label>
                    Enter User ID:
                    <input type="text" value={userId} onChange={handleUserIdChange} />
                </label>
                <button type="submit">Get Permissions</button>
            </form>
            <div>
                {response && (
                    <div>
                        <h2>User Information:</h2>
                        <p>Custom ID: {response.user.custom_id}</p>
                        <p>Mobile Number: {response.user.mobile_number}</p>
                        <p>Category: {response.user.category}</p>
                        <p>Is Active: {response.user.is_active ? "Active" : "Inactive"}</p>
                        
                        <h2>Roles and Permissions:</h2>
                        <p>Name: {response.roles_and_permissions.name}</p>
                        <p>Short Code: {response.roles_and_permissions.short_code}</p>
                        <h3>Permissions:</h3>
                        <ul>
                            {response.roles_and_permissions.permissions.map((permission, index) => (
                                <li key={index}>{permission}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UserRoleAndPermissionsPage;
