import axios from 'axios';
import { getTokens, removeTokens } from './tokens';
import toastr from "toastr";

export const staging = false;
export const apiRootLive = "https://backend.idbookhotels.com/api/v1";
export const apiRootStaging = 'https://backend.idbookhotels.com/api/v1';
export const apiRoot = !staging ? apiRootLive : apiRootStaging;


export const axiosApi = axios.create({
    baseURL: apiRoot,
    timeout: 60000,
    headers: {

    },
    // headers: {
    //     'Access-Control-Allow-Origin': '*',
    //     'Access-Control-Allow-Headers': '*'
    // },
});

// Add a request interceptor
axiosApi.interceptors.request.use(
    async function (config) {
        // console.log("REQUEST_URL", config.url);
        const token = await getTokens();

        // Set the token in the request header
        if (token && token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    },
);

// Add a response interceptor
axiosApi.interceptors.response.use(
    (response) => {
        if (response.data) {
            return response.data;
        }
        else {
            var message = 'We had trouble connecting to the server';
            if (response.data.message) message = response.data.message;
            return Promise.reject(response);
        }
    },
    async (err) => {
        const originalConfig = err.config;

        if (err.response) {
            // Access Token was expired
            if (err.response.status === 401) {
                console.log("ResStatus_&&_ReqUrl::", err.response.status + originalConfig.url)
                console.log(err?.response?.data?.message)
                toastr.error('Session expired.Please login again...!!!');
                await removeTokens();
            } else if (err.response.status === 404) {
                // 'Not Found: The resources requested could not be found on this server';
                console.log("MANAGE PAGE NOT FOUND CONDITION");
            } else if (err.response.status === 503) {
                // 'Service Unavailable: Please try after some time';
                console.log("MANAGE SERVICE UNAVAILABLE");
            }
        }
        return Promise.reject(err);
    }
);
