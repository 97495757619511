import React from 'react';
import Image from '../../assets/images/image1.svg';
import Flag from '../../assets/images/flag.svg'
import UserService from '../../services/UserService';
import RoleService from '../../services/RoleService';



const Dashboard = () => {
    return (
        <div className='dashboard'>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='statistics_outer'>
                        <h2 className='lbl_general_statics'>General statistics</h2>
                        <div className='earning_today_outer'>
                            <div className='today_earning_wrapper'>
                                <div>
                                    <p className='today_earning_title'>Today’s Money</p>
                                    <h4 className='money_count'>$53,000 <span className='money_updown'>+55%</span></h4>
                                </div>
                                <div className='today_earning_icon'>
                                    <img src={Image} />
                                </div>
                            </div>

                            <div className='today_earning_wrapper'>
                                <div>
                                    <p className='today_earning_title'>Today’s Money</p>
                                    <h4 className='money_count'>$53,000 <span className='money_updown'>+55%</span></h4>
                                </div>
                                <div className='today_earning_icon'>
                                    <img src={Image} />
                                </div>
                            </div>

                            <div className='today_earning_wrapper'>
                                <div>
                                    <p className='today_earning_title'>Today’s Money</p>
                                    <h4 className='money_count'>$53,000 <span className='money_updown'>+55%</span></h4>
                                </div>
                                <div className='today_earning_icon'>
                                    <img src={Image} />
                                </div>
                            </div>

                            <div className='today_earning_wrapper'>
                                <div>
                                    <p className='today_earning_title'>Today’s Money</p>
                                    <h4 className='money_count'>$53,000 <span className='money_updown'>+55%</span></h4>
                                </div>
                                <div className='today_earning_icon'>
                                    <img src={Image} />
                                </div>
                            </div>
                        </div>

                        <div className='sales_by_country table_wrapper'>
                            <h3 className='lbl_sales_by_country'>Sales by Country</h3>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='country_flag'>
                                            <div>
                                                <img src={Flag} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='country_name'>
                                                <p className='lt_title'>Country:</p>
                                                <h5 className='lt_value'>United states</h5>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='sales'>
                                                <p className='lt_title'>Sales:</p>
                                                <h5 className='lt_value'>2500</h5>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='sale_value'>
                                                <p className='lt_title'>Value:</p>
                                                <h5 className='lt_value'>$230900</h5>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='bounce_in_percent'>
                                                <p className='lt_title'>Bounce:</p>
                                                <h5 className='lt_value'>29.9%</h5>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='country_flag'>
                                            <div>
                                                <img src={Flag} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='country_name'>
                                                <p className='lt_title'>Country:</p>
                                                <h5 className='lt_value'>United states</h5>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='sales'>
                                                <p className='lt_title'>Sales:</p>
                                                <h5 className='lt_value'>2500</h5>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='sale_value'>
                                                <p className='lt_title'>Value:</p>
                                                <h5 className='lt_value'>$230900</h5>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='bounce_in_percent'>
                                                <p className='lt_title'>Bounce:</p>
                                                <h5 className='lt_value'>29.9%</h5>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='country_flag'>
                                            <div>
                                                <img src={Flag} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='country_name'>
                                                <p className='lt_title'>Country:</p>
                                                <h5 className='lt_value'>United states</h5>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='sales'>
                                                <p className='lt_title'>Sales:</p>
                                                <h5 className='lt_value'>2500</h5>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='sale_value'>
                                                <p className='lt_title'>Value:</p>
                                                <h5 className='lt_value'>$230900</h5>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='bounce_in_percent'>
                                                <p className='lt_title'>Bounce:</p>
                                                <h5 className='lt_value'>29.9%</h5>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='country_flag'>
                                            <div>
                                                <img src={Flag} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='country_name'>
                                                <p className='lt_title'>Country:</p>
                                                <h5 className='lt_value'>United states</h5>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='sales'>
                                                <p className='lt_title'>Sales:</p>
                                                <h5 className='lt_value'>2500</h5>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='sale_value'>
                                                <p className='lt_title'>Value:</p>
                                                <h5 className='lt_value'>$230900</h5>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='bounce_in_percent'>
                                                <p className='lt_title'>Bounce:</p>
                                                <h5 className='lt_value'>29.9%</h5>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>


                    {/* SOMETHING HERE OF COL-6 */}


                </div>
            </div>


            <div className='row my-4'>
                <div className='col-md-5'>
                    <div className='users_and_sales'>
                        <div className='dash_bar_chart'>

                        </div>
                        <div className='active_users_wrapper'>

                        </div>
                    </div>
                </div>
                <div className='col-md-7'>

                </div>
            </div>
        </div>
    )
}

export default Dashboard;