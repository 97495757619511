import React, { useEffect, useState } from "react";
import TermsAndConditionsService from "../../services/org_resources/TermsAndConditionsService"; // Import your TermsAndConditionsService

const TermsAndConditionsPage = () => {
    const [termsAndConditions, setTermsAndConditions] = useState({
        id: null,
        title: "",
        description: "",
        image: null,
        active: false,
        created: "",
        updated: "",
    });

    const fetchTermsAndConditions = async () => {
        try {
            const response = await TermsAndConditionsService.getTermsAndConditions();
            if (response.status === 'success') {
                setTermsAndConditions(response.data);
            }
        } catch (error) {
            // Handle errors
        }
    };

    useEffect(() => {
        fetchTermsAndConditions();
    }, []);

    return (
        <div>
            <h1>{termsAndConditions.title}</h1>
            <p>{termsAndConditions.description}</p>
        </div>
    );
};

export default TermsAndConditionsPage;
