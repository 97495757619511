import axios from "axios";
import { axiosApi } from "../helpers/api";

const API_BASE_URL = 'https://backend.idbookhotels.com/api/v1/administrator'


class UserService {

    getUsers(limit, offset) {
        return axiosApi.get(`/administrator/users/?limit=${limit}&offset=${offset}`);
    }

    // getUsers() {
    //     return axiosApi.get('/administrator/users/');
    // }

    getUserDetailByCustomId(mobileNumber, token) {

        const url = `${API_BASE_URL}/users/${mobileNumber}/roles_and_permissions/`;

        return axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {

                return response.data;
            })
            .catch(error => {
                console.log(error);
                throw error;
            });
    }

    getUserByMobile(mobile) {
        return axiosApi.get(`/administrator/users/${mobile}/`);
    }

    updateUser(data, mobile_number) {
        return axiosApi.put(`/administrator/users/${mobile_number}/`, data);
    }
}

export default new UserService();