import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'toastr/build/toastr.min.css';
import '../src/assets/scss/app.scss';

import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Layout/Header';
import SideBar from './components/Layout/SideBar';

import Dashboard from './pages/Dashboard/Dashboard';
import LoginPage from './pages/AuthPages/LoginPage/LoginPage';

import UsersPage from './pages/UsersPage/UsersPage';
import DetailView from './pages/UsersPage/detailView';
import UpdateView from './pages/UsersPage/UpdateView';
import UserRoleAndPermissionsPage from './pages/UserRolesAndPermissions/UserRoleAndPermissionsPage';

import RolesPage from './pages/RolesPage/RolesPage';
import RoleUpdateView from './pages/RolesPage/RoleUpdateView';

import PermissionPage from './pages/PermissionsPage/PermissionsPage';

import BookingsPage from './pages/BookingPage/BookingsPage';
import AppliedCouponsPage from './pages/BookingPage/AppliedCouponsPage';

import CouponsPage from './pages/CouponPage/CouponsPage';
import CustomersPage from './pages/CustomerPage/CustomersPage';

import PropertiesPage from './pages/HotelPage/PropertiesPage';
import RoomsPage from './pages/HotelPage/RoomsPage';
import GalleryPage from './pages/HotelPage/GalleriesPage';
import ReviewsPage from './pages/HotelPage/ReviewPage';

import CompanyDetailPage from './pages/ORGResourcesPage/CompanyDetailPage';
import AmenityCategoryPage from './pages/ORGResourcesPage/AmenityCategoryPage';
import AmenityPage from './pages/ORGResourcesPage/AmenityPage';
import EnquiryPage from './pages/ORGResourcesPage/EnquiryPage';
import RoomTypePage from './pages/ORGResourcesPage/RoomTypePage';
import OccupancyPage from './pages/ORGResourcesPage/OccupancyPage';
import AboutUsPage from './pages/ORGResourcesPage/AboutUsPage';
import PrivacyPolicyPage from './pages/ORGResourcesPage/PrivacyPolicyPage';
import RefundAndCancellationPolicyPage from './pages/ORGResourcesPage/RefundAndCancellationPolicyPage';
import TermsAndConditionsPage from './pages/ORGResourcesPage/TermsAndConditionsPage';
import LegalityPage from './pages/ORGResourcesPage/LegalityPage';
import CareerPage from './pages/ORGResourcesPage/CareerPage';
import FaqPage from './pages/ORGResourcesPage/FAQsPage';


function Layout({ children }) {
  const location = useLocation();
  // const isLoginPage = location.pathname === '/login';
  const isLoginPage = location.pathname === '/';

  if (isLoginPage) {
    return (
      <div>
        {/* Render only children, without SideBar and Header */}
        {children}
      </div>
    );
  }

  return (
    <div className="app-container">
      <SideBar />
      <div className="main-container">
        <Header />

        <div className='main'>
          {children}
        </div>

        {/* <Footer /> */}
      </div>
    </div>
  );
}




function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/dashboard' element={<Layout><Dashboard /></Layout>} />
        <Route path='/' element={<LoginPage />} />

        <Route path="/roles/update/:id" element={<Layout> <RoleUpdateView/> </Layout>} />

        <Route path="/users" element={<Layout> <UsersPage /> </Layout>} />
        <Route path="/user/create" element={<Layout><div><h2>User Create</h2></div></Layout>} />
        <Route path="/detail/:mobileNumber" element={<Layout> <DetailView/> </Layout>} />
        <Route path="/user/update/:mobileNumber" element={<Layout> <UpdateView /> </Layout>} />
        <Route path="/get_role_and_permission_by_user" element={<Layout> <UserRoleAndPermissionsPage /> </Layout>} />
        
        <Route path="/roles" element={<Layout> <RolesPage /> </Layout>} />
        <Route path="/role/create" element={<Layout><div><h2>Role Create</h2></div></Layout>} />
        <Route path="/role/update" element={<Layout><div><h2>Role Update</h2></div></Layout>} />
        
        <Route path="/permissions" element={<Layout> <PermissionPage /> </Layout>} />
        
        <Route path="/bookings" element={<Layout> <BookingsPage /> </Layout>} />
        <Route path="/applied-coupons" element={<Layout> <AppliedCouponsPage /> </Layout>} />

        <Route path="/coupons" element={<Layout> <CouponsPage /> </Layout>} />

        <Route path="/customers" element={<Layout> <CustomersPage /> </Layout>} />
        
        <Route path="/properties" element={<Layout> <PropertiesPage /> </Layout>} />
        <Route path="/rooms" element={<Layout> <RoomsPage /> </Layout>} />
        <Route path="/galleries" element={<Layout> <GalleryPage /> </Layout>} />
        <Route path="/reviews" element={<Layout> <ReviewsPage /> </Layout>} />

        <Route path="/company-details" element={<Layout> <CompanyDetailPage /> </Layout>} />
        <Route path="/amenity-categories" element={<Layout> <AmenityCategoryPage /> </Layout>} />
        <Route path="/amenities" element={<Layout> <AmenityPage /> </Layout>} />
        <Route path="/enquiries" element={<Layout> <EnquiryPage /> </Layout>} />
        <Route path="/room-types" element={<Layout> <RoomTypePage /> </Layout>} />
        <Route path="/occupancies" element={<Layout> <OccupancyPage /> </Layout>} />
        <Route path="/about-us" element={<Layout> <AboutUsPage /> </Layout>} />
        <Route path="/privacy-policy" element={<Layout> <PrivacyPolicyPage /> </Layout>} />
        <Route path="/refund-and-cancellation-policy" element={<Layout> <RefundAndCancellationPolicyPage /> </Layout>} />
        <Route path="/terms-and-conditions" element={<Layout> <TermsAndConditionsPage /> </Layout>} />
        <Route path="/legality" element={<Layout> <LegalityPage /> </Layout>} />
        <Route path="/career" element={<Layout> <CareerPage /> </Layout>} />
        <Route path="/faqs" element={<Layout> <FaqPage /> </Layout>} />

        <Route path="/pages" element={<Layout><div><h2>Pages</h2></div></Layout>} />
        <Route path="/applications" element={<Layout><div><h2>Applications</h2></div></Layout>} />
        <Route path="/ecommerce" element={<Layout><div><h2>E-commerce</h2></div></Layout>} />
        <Route path="/authentication" element={<Layout><div><h2>Authentication</h2></div></Layout>} />
        <Route path="/changelog" element={<Layout><div><h2>Change-log</h2></div></Layout>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
