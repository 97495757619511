import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";
import toastr from "toastr";
import { Button, Table, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTable, useSortBy } from "react-table";

import { PiEyeBold } from 'react-icons/pi';
import { BiSolidPencil, BiSolidTrashAlt, BiSearch } from 'react-icons/bi';
import { MdOutlineAddCircleOutline } from 'react-icons/md'
import DataTable from "../../components/Pages/DataTable";


const tableColumn = [
    {
        Header: "ID",
        accessor: "id"
    },
    {
        Header: "Custom Id",
        accessor: "custom_id"
    },
    {
        Header: "Category",
        accessor: "category"
    },
    {
        Header: "Mobile",
        accessor: "mobile_number"
    },
    {
        Header: "Name",
        accessor: "first_name"
    },
    {
        Header: "Email",
        accessor: "email"
    },
    {
        Header: "Status",
        accessor: "is_active",
        Cell: ({ row }) => (
            <div>
                {row.values.is_active ?
                    <Badge className="badge_active">Active</Badge>
                    :
                    <Badge className="badge_deactive">Inactive</Badge>
                }
            </div>

        )
    },
    {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
            <div className="d-flex align-items-center gap-3">
                <Link to={`/detail/${row.values.mobile_number}`}><PiEyeBold color="#F89752" /></Link>
                <Link to={`/user/update/${row.values.mobile_number}`}> <BiSolidPencil color="#1AB69D" /></Link>
            </div>
        )
    }
]

const UsersPage = () => {
    const navigate = useNavigate();

    const [tableData, setTableData] = useState({
        data: [],
        pageCount: 0,
        pageIndex: 0,
        pageSize: 8,
        previous: null,
        next: null,
    });

    const columns = useMemo(() => tableColumn, []);

    const getUserData = async (limit, offset) => {
        await UserService.getUsers(limit, offset).then((res) => {
            if (res.status === 'success') {
                setTableData({
                    data: res.data.results,
                    pageCount: Math.ceil(res.data.count / tableData.pageSize),
                    pageIndex: tableData.pageIndex,
                    pageSize: tableData.pageSize,
                    previous: res.data.previous,
                    next: res.data.next,
                });
            }
        });
    }

    useEffect(() => {
        getUserData(tableData.pageSize, tableData.pageIndex * tableData.pageSize);
    }, [tableData.pageIndex]);

    const onPressSetPageIndex = (newPageIndex) => {
        console.log("Page_index", newPageIndex)
        setTableData({ ...tableData, pageIndex: newPageIndex })
    }


    return (
        <div className="table_wrapper">
            <div className="d-flex justify-content-end mb-3">
                <div className="search_box">
                    <BiSearch />
                    <input type="search" placeholder="Type here..." />
                </div>
                <Link className="add_btn"> <MdOutlineAddCircleOutline className="add_btn_icon" /> Add new User </Link>
            </div>

            <div>
                {tableData &&
                    <DataTable
                        data={tableData.data}
                        columns={columns}
                        pageIndex={tableData.pageIndex}
                        pageCount={tableData.pageCount}
                        setPageIndex={(newPageIndex) => onPressSetPageIndex(newPageIndex)}
                        previous={tableData.previous}
                        next={tableData.next}
                    />
                }
            </div>
        </div>
    )
}


export default UsersPage;