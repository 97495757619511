import React, { useState } from 'react';

import {
    FaTh, FaUserAlt, FaRegChartBar, FaCommentAlt,
    FaShoppingBag, FaThList, FaBars, FaChevronDown, FaChevronRight, FaKeycdn,
    FaUserCog, FaUsers
} from 'react-icons/fa'
import { NavLink, useLocation } from 'react-router-dom';
import Logo from '../../assets/images/logo.svg';
import LogoPng from '../../assets/images/idbook_logo.png';

const SideMenuItem = ({ icon, name, path, isOpen, subMenus }) => {
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

    const toggleSubMenu = () => {
        setIsSubMenuOpen(!isSubMenuOpen);
    };

    return (
        <div>
            {
                subMenus.length > 0
                    ?
                    (
                        <div className={`link mainMenu_link ${!isOpen && 'justify-content-center'}`} activeclassname='active' onClick={toggleSubMenu} >
                            <div className='d-flex align-items-center'>
                                <div className='icon'>{icon}</div>
                                <div className='link_text' style={{ display: isOpen ? 'block' : 'none' }}>{name}</div>
                            </div>

                            <div className='arrow_icon_wrapper'>
                                {isSubMenuOpen ? <FaChevronDown /> : <FaChevronRight />}
                            </div>
                        </div>
                    )
                    :
                    (
                        <NavLink to={path} className={`link mainMenu_link ${!isOpen && 'justify-content-center'}`} activeclassname='active' >
                            <div className='d-flex align-items-center'>
                                <div className='icon'>{icon}</div>
                                <div className='link_text' style={{ display: isOpen ? 'block' : 'none' }}>{name}</div>
                            </div>

                            <div className='arrow_icon_wrapper'>
                                <FaChevronRight />
                            </div>
                        </NavLink>
                    )
            }

            {
                isSubMenuOpen &&
                <div className='Sub_Menu'>
                    {
                        subMenus.length > 0 && (
                            <div>
                                {subMenus.map((submenu, submenuIndex) => (
                                    <NavLink to={submenu.path} key={submenuIndex} className='link submenu_link' activeclassname='active'>
                                        <div className='d-flex align-items-center'>
                                            <div className='icon'>{submenu.icon}</div>
                                            <div className='link_text' style={{ display: isOpen ? 'block' : 'none' }}>{submenu.name}</div>
                                        </div>
                                    </NavLink>
                                ))}
                            </div>
                        )
                    }
                </div>
            }
        </div>
    );
};


export default SideMenuItem;