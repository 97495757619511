import { axiosApi } from "../../helpers/api";

const BASE_API_URL = 'https://backend.idbookhotels.com/api/v1/customer/customers/' // Adjust the API endpoint accordingly

class CustomersService {
    getCustomers(limit, offset) {
        return axiosApi.get(`/customer/customers/?limit=${limit}&offset=${offset}`);
    }
    // getCustomers() {
    //     return axiosApi.get(BASE_API_URL);
    // }

    getCustomerById(id) {
        return axiosApi.get(`${BASE_API_URL}${id}/`);
    }

    createCustomer(data) {
        return axiosApi.post(BASE_API_URL, data);
    }

    updateCustomer(data, id) {
        return axiosApi.put(`${BASE_API_URL}${id}/`, data);
    }

    deleteCustomer(id) {
        return axiosApi.delete(`${BASE_API_URL}${id}/`);
    }
}

export default new CustomersService();
