import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import RoleService from "../../services/RoleService"; // Import your RoleService
import toastr from "toastr";

const RoleUpdateView = () => {
    const navigate = useNavigate();
    const { roleId } = useParams();
    const [roleDetail, setRoleDetail] = useState(null);
    const [updateRoleDetails, setUpdateRoleDetails] = useState({
        "name": "",
        "short_code": "",
        "permissions": []
    });

    const onInputChange = (e) => {
        setUpdateRoleDetails({ ...updateRoleDetails, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        RoleService.getRoleById(roleId)
            .then((res) => {
                setRoleDetail(res.data);

                if (res.data) {
                    setUpdateRoleDetails({
                        "name": res?.data?.name ?? "",
                        "short_code": res?.data?.short_code ?? "",
                        "permissions": res?.data?.permissions ?? []
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [roleId]);

    const updateRole = async (e) => {
        e.preventDefault();
        await RoleService.updateRole(updateRoleDetails, roleId)
            .then((res) => {
                if (res.status === 'success') {
                    toastr.success('Role Updated Successfully');
                    navigate('/roles');
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div>
            {roleDetail && (
                <form onSubmit={updateRole}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Role Name</label>
                                <input
                                    type="text"
                                    value={updateRoleDetails.name}
                                    className="form-control"
                                    name="name"
                                    placeholder="Role Name"
                                    onChange={(e) => onInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="short_code" className="form-label">Short Code</label>
                                <input
                                    type="text"
                                    value={updateRoleDetails.short_code}
                                    className="form-control"
                                    name="short_code"
                                    placeholder="Short Code"
                                    onChange={(e) => onInputChange(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="mt-3">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
};

export default RoleUpdateView;
