
export const setTokens = async (token) => {
    // token is access
    window.localStorage.setItem("authorization", token);
}

export const getTokens = async () => {
    let token = window.localStorage.getItem("authorization")
    return token;
}

export const removeTokens = async () => {
    window.localStorage.removeItem("authorization");
}