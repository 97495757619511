import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReviewService from "../../services/hotels/ReviewsService"; // Import your ReviewService
import toastr from "toastr";
import { Button, Table } from 'react-bootstrap';
import { useTable, useSortBy } from "react-table";

import {
    PiEyeBold
} from 'react-icons/pi';
import {
    BiSolidPencil, BiSolidTrashAlt, BiSearch
} from 'react-icons/bi';
import { MdOutlineAddCircleOutline } from 'react-icons/md'
import DataTable from "../../components/Pages/DataTable";

const tableColumn = [
    {
        Header: "ID",
        accessor: "id"
    },
    {
        Header: "Name",
        accessor: "name"
    },
    {
        Header: "Email",
        accessor: "email"
    },
    {
        Header: "Body",
        accessor: "body"
    },
    {
        Header: "Check-in Rating",
        accessor: "check_in_rating"
    },
    {
        Header: "Breakfast",
        accessor: "breakfast"
    },
    {
        Header: "Cleanliness",
        accessor: "cleanliness"
    },
    {
        Header: "Comfort",
        accessor: "comfort"
    },
    {
        Header: "Hotel Staff",
        accessor: "hotel_staff"
    },
    {
        Header: "Facilities",
        accessor: "facilities"
    },
    {
        Header: "Overall",
        accessor: "over_all"
    },
    {
        Header: "Created",
        accessor: "created"
    },
    {
        Header: "Updated",
        accessor: "updated"
    },
    {
        Header: "Active",
        accessor: "active"
    },
    {
        Header: "Property",
        accessor: "property"
    },
    {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
            <div className="d-flex align-items-center gap-3">
                <Link to={`/reviews/detail/${row.values.id}`}><PiEyeBold color="#F89752" /></Link>
                <Link to={`/reviews/update/${row.values.id}`}><BiSolidPencil color="#1AB69D" /></Link>
            </div>
        )
    }
];

const ReviewPage = () => {
    const navigate = useNavigate();
    const columns = useMemo(() => tableColumn, []);

    const [tableData, setTableData] = useState({
        data: [],
        pageCount: 0,
        pageIndex: 0,
        pageSize: 8,
        previous: null,
        next: null,
    });

    const getReviewData = async (limit, offset) => {
        await ReviewService.getReviews(limit, offset).then((res) => {
            if (res.status === 'success') {
                setTableData({
                    data: res.data.results,
                    pageCount: Math.ceil(res.data.count / tableData.pageSize),
                    pageIndex: tableData.pageIndex,
                    pageSize: tableData.pageSize,
                    previous: res.data.previous,
                    next: res.data.next,
                });
            }
        });
    }

    useEffect(() => {
        getReviewData(tableData.pageSize, tableData.pageIndex * tableData.pageSize);
    }, [tableData.pageIndex]);

    const onPressSetPageIndex = (newPageIndex) => {
        setTableData({ ...tableData, pageIndex: newPageIndex })
    }

    return (
        <div className="table_wrapper">
            <div className="d-flex justify-content-end mb-3">
                <div className="search_box">
                    <BiSearch />
                    <input type="search" placeholder="Type here..." />
                </div>
                <Link className="add_btn"> <MdOutlineAddCircleOutline className="add_btn_icon" /> Add new Review </Link>
            </div>

            <div>
                {tableData &&
                    <DataTable
                        data={tableData.data}
                        columns={columns}
                        pageIndex={tableData.pageIndex}
                        pageCount={tableData.pageCount}
                        setPageIndex={(newPageIndex) => onPressSetPageIndex(newPageIndex)}
                        previous={tableData.previous}
                        next={tableData.next}
                    />
                }
            </div>
        </div>
    )
}

export default ReviewPage;
