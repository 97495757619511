import { axiosApi } from "../../helpers/api";

const BASE_API_URL = 'https://backend.idbookhotels.com/api/v1/org-resources/legality/'  // Replace with your Legality API endpoint

class LegalityService {
    // Retrieve the "Legality" content
    getLegality() {
        return axiosApi.get(BASE_API_URL);
    }
}

export default new LegalityService();
