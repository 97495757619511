import React from "react";

import {
    FaUserAlt
} from 'react-icons/fa'
import { RiSettings4Fill } from 'react-icons/ri';
import { BiSolidBell, BiSearch } from 'react-icons/bi'
import LoginService from "../../services/LoginService";
import { Link, useNavigate } from 'react-router-dom'


const Header = () => {
    const navigate = useNavigate();

    const onLogout = (e) => {
        e.preventDefault();
        LoginService.logout();
        // navigate('/login');
        navigate('/');
    }

    return (
        <div className="main_header">
            <div className="header">
                <h2 className="lbl_page_name">Default</h2>
                <ul className="mb-0">
                    {/* <li className="header_menu">
                        <div className="search_box">
                            <BiSearch />
                            <input type="search" placeholder="Type here..." />
                        </div>
                    </li> */}

                    {/* <li className="header_menu">
                        <div className="d-flex align-items-center">
                            <FaUserAlt />
                            <p className="lbl_signin mb-0 ms-2">Sign In</p>
                        </div>
                    </li> */}
                    <li className="header_menu">
                        <button type="button" onClick={onLogout} className="d-flex align-items-center logout_btn">
                            <FaUserAlt />
                            <p className="lbl_signin mb-0 ms-2">Sign Out</p>
                        </button>
                    </li>
                    <li className="header_menu">
                        <RiSettings4Fill />
                    </li>
                    <li className="header_menu">
                        <BiSolidBell />
                    </li>
                </ul>
            </div>
        </div>
    )
}


export default Header;