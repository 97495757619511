import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom'
import LoginService from "../../../services/LoginService";
import BannerImg from '../../../assets/images/login_bg.svg';
import EyeIcon from '../../../assets/images/icons/eye.svg';
import PhoneIcon from '../../../assets/images/icons/phone.svg';
import Logo from '../../../assets/images/idbook_logo.png';
import toastr from "toastr";

const LoginPage = () => {
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState('');
    const [mobileErr, setMobileErr] = useState('');
    const [passwordErr, setPasswordErr] = useState('');
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [loginInfo, setLoginInfo] = useState({
        mobile_number: "",
        password: ""
    });

    const { mobile_number, password } = loginInfo;


    const onInputChange = (e) => {
        setLoginInfo({ ...loginInfo, [e.target.name]: e.target.value })
    }

    const checkValidation = () => {
        if (mobile_number.length === 0 || password.length === 0) {
            if (mobile_number.length === 0) {
                setMobileErr("Please Enter Mobile Number");
                toastr.error("Please Enter Mobile Number");
            } else if (mobile_number.length !== 10) {
                setMobileErr("Please Enter Valid 10 Digit Mobile Number");
                toastr.error("Please Enter Valid 10 Digit Mobile Number");
            } else {
                setMobileErr("");
            }

            if (password.length === 0) {
                setPasswordErr("Please Enter Password");
                toastr.error("Please Enter Password");
            } else {
                setPasswordErr("");
            }
            return false;
        } else {
            setMobileErr("");
            setPasswordErr("");
            return true;
        }
    }


    const login = async (e) => {
        e.preventDefault();

        let isValidCredentials = checkValidation();
        if (isValidCredentials) {
            try {
                const res = await LoginService.login(loginInfo);
                const isActive = res?.data?.[0]?.is_active;
                const category = res?.data?.[0]?.category;
                if (res?.status === "success" && isActive && category === 'Admin') {
                    toastr.success(res?.message);
                    navigate('/dashboard');
                } else if (res?.status === 'failed') {
                    setErrorMessage(res.message);
                    toastr.error(res?.message);
                } else if (category !== 'Admin') {
                    toastr.error("You are not authorised to login");
                }
            } catch (error) {
                setErrorMessage("Please Enter Valid Email and Password");
                if (error.response) {
                    const errorMessage = error.response?.data?.message || 'An error occurred.';
                    toastr.error(errorMessage);
                } else {
                    toastr.error('An error occurred.');
                }
            }
        } else {
            setErrorMessage("Please Enter Valid Email and Password");
        }
    }

    const togglePasswordType = (e) => {
        e.preventDefault();
        setIsShowPassword((prev) => !prev);
    }


    return (
        <div className="login_page_outer">
            <div className="login_logo">
                <img src={Logo} />
            </div>

            <div className="container">
                <div className="login_wrapper">
                    <div className="row">
                        <div className="col-lg-7 col-md-6">
                            <div className="login_banner">
                                <img src={BannerImg} />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6">
                            <div className="login_inner">
                                <h2 className="login_title">Sign in your account</h2>
                                <p className="login_desc">Enter your phone number and password below to sign in</p>

                                <form className="loginForm" onSubmit={login}>
                                    <div className="form-group">
                                        <input type="text" onChange={(e) => onInputChange(e)} value={mobile_number} className="custom_input email custom_input" name="mobile_number" placeholder="Mobile" />
                                        <img src={PhoneIcon} />
                                    </div>
                                    {mobileErr !== '' &&
                                        <p className="input_error">{mobileErr}</p>
                                    }
                                    <div className="form-group">
                                        <input type={isShowPassword ? 'text' : 'password'} onChange={(e) => onInputChange(e)} value={password} className="custom_input password custom_input" name="password" placeholder="Password" />
                                        <div className="password_eye" onClick={togglePasswordType}>
                                            <img src={EyeIcon} />
                                        </div>
                                    </div>
                                    {passwordErr !== '' &&
                                        <p className="input_error">{passwordErr}</p>
                                    }
                                    {errorMessage && <p className="input_error">{errorMessage}</p>}
                                    <div>
                                        <button className="btn_login" type="submit" onClick={login}>Sign in</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default LoginPage;