import { axiosApi } from "../../helpers/api";

const BASE_API_URL = 'https://backend.idbookhotels.com/api/v1/org-resources/about-us/'  // Replace with your About Us API endpoint

class AboutUsService {
    // Retrieve "About Us" content
    getAboutUs() {
        return axiosApi.get(BASE_API_URL);
    }

    // Update "About Us" content
    updateAboutUs(data) {
        return axiosApi.put(BASE_API_URL, data);
    }
}

export default new AboutUsService();
