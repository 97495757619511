import { axiosApi } from "../../helpers/api";

const BASE_API_URL = 'https://backend.idbookhotels.com/api/v1/hotels/rooms/'


class RoomService {
    getRooms(limit, offset) {
        return axiosApi.get(`/hotels/rooms/?limit=${limit}&offset=${offset}`);
    }
    // getRooms() {
    //     return axiosApi.get(BASE_API_URL);
    // }

    getRoomById(id) {
        return axiosApi.get(`${BASE_API_URL}${id}/`);
    }

    createRoom(data) {
        return axiosApi.post(BASE_API_URL, data);
    }

    updateRoom(data, id) {
        return axiosApi.put(`${BASE_API_URL}${id}/`, data);
    }

}

export default new RoomService();
