import React, { useEffect, useState } from "react";
import RefundAndCancellationPolicyService from "../../services/org_resources/RefundAndCancellationPolicyService"; // Import your RefundAndCancellationPolicyService

const RefundAndCancellationPolicyPage = () => {
    const [refundAndCancellationPolicy, setRefundAndCancellationPolicy] = useState({
        id: null,
        title: "",
        description: "",
        image: null,
        active: false,
        created: "",
        updated: "",
    });

    const fetchRefundAndCancellationPolicy = async () => {
        try {
            const response = await RefundAndCancellationPolicyService.getRefundAndCancellationPolicy();
            if (response.status === 'success') {
                setRefundAndCancellationPolicy(response.data);
            }
        } catch (error) {
            // Handle errors
        }
    };

    useEffect(() => {
        fetchRefundAndCancellationPolicy();
    }, []);

    return (
        <div>
            <h1>{refundAndCancellationPolicy.title}</h1>
            <p>{refundAndCancellationPolicy.description}</p>
        </div>
    );
};

export default RefundAndCancellationPolicyPage;
