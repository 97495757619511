import { axiosApi } from "../../helpers/api";

const BASE_API_URL = 'https://backend.idbookhotels.com/api/v1/booking/bookings/' // Adjust the API endpoint accordingly

class BookingsService {
    getBookings(limit, offset) {
        return axiosApi.get(`/booking/bookings/?limit=${limit}&offset=${offset}`);
    }
    // getBookings() {
    //     return axiosApi.get(BASE_API_URL);
    // }

    getBookingById(id) {
        return axiosApi.get(`${BASE_API_URL}${id}/`);
    }

    createBooking(data) {
        return axiosApi.post(BASE_API_URL, data);
    }

    updateBooking(data, id) {
        return axiosApi.put(`${BASE_API_URL}${id}/`, data);
    }

    deleteBooking(id) {
        return axiosApi.delete(`${BASE_API_URL}${id}/`);
    }
}

export default new BookingsService();
